var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents-section"},[_c('div',{staticClass:"title-section"},[_c('h2',{staticClass:"d-flex align-start"},[_vm._v(" "+_vm._s(_vm.boardName)+" "),_c('v-tooltip',{attrs:{"right":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 ml-1",attrs:{"icon":"","loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.getSummary()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("새로고침")])])],1),(
        (_vm.boardNo === 0 && this.userInfo.permission.admin.menu.notice.bool === true)
        || _vm.boardNo !== 0
      )?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){_vm.registDialog = { show: true, title: null, content: null, type: 0, files: [] }}}},[_vm._v(" 게시글작성 ")]):_vm._e()],1),_c('div',{staticClass:"main-section"},[_c('search-filter',{attrs:{"search":_vm.search,"type":"server","searchPlaceHolder":"등록인/제목으로 검색"},on:{"updateSearch":_vm.updateSearch}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.lists,"item-key":"no","options":_vm.options,"loading":_vm.loading,"dense":"","checkbox-color":"primary","server-items-length":_vm.totalItems,"hide-default-footer":""},on:{"update:sort-by":function($event){return _vm.updateOptions('sortBy', $event)},"update:sort-desc":function($event){return _vm.updateOptions('sortDesc', $event)}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"cs-title",on:{"click":function($event){return _vm.contentsView(item.no)}}},[_vm._v(_vm._s(item.title))])]}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.time.makeLocalTime(item.created, 'min'))+" ")]}},{key:"item.customer",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s((" " + (item.customerName) + " (" + (item.customerPhone.slice(-4)) + ") "))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.customerPhone))])])]}},{key:"item.manager",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"pl-1 pr-1",attrs:{"small":"","label":"","outlined":""}},[_vm._v(_vm._s(_vm.comm.getGroupFullPath(_vm.groups, item.managerGroupNo, '그룹없음')))]),_vm._v(" "+_vm._s(item.managerName)+" ")]}},{key:"item.creator",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"pl-1 pr-1",attrs:{"small":"","label":"","outlined":""}},[_vm._v(_vm._s(_vm.comm.getGroupFullPath(_vm.groups, item.creatorGroupNo, '그룹없음')))]),_vm._v(" "+_vm._s(item.creatorName)+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(
        _vm.userInfo !== null
        && _vm.userInfo.permission.cs.menu.cs.func.status
        && _vm.userInfo.permission.cs.menu.cs.func.status.bool === true)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"pr-2",attrs:{"small":"","link":"","outlined":"","color":item.status === 1
              ? 'info'
              : (item.status === 2 ? 'success' : '')}},'v-chip',attrs,false),on),[(item.status === 1)?_c('span',[_vm._v("진행중")]):(item.status === 2)?_c('span',[_vm._v("처리완료")]):_c('span',[_vm._v("미확인")]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('v-list',[(item.status !== 1)?_c('v-list-item',{attrs:{"dense":"","nav":"","link":""},on:{"click":function($event){return _vm.updateStatus(item.no, 1)}}},[_c('v-list-item-title',[_vm._v("진행중으로 변경")])],1):_vm._e(),(item.status !== 2)?_c('v-list-item',{attrs:{"dense":"","nav":"","link":""},on:{"click":function($event){return _vm.updateStatus(item.no, 2)}}},[_c('v-list-item-title',[_vm._v("처리완료로 변경")])],1):_vm._e()],1)],1):_c('v-chip',{attrs:{"small":"","color":item.status === 1
          ? 'info'
          : (item.status === 2 ? 'success' : '')}},[(item.status === 1)?_c('span',[_vm._v("진행중")]):(item.status === 2)?_c('span',[_vm._v("처리완료")]):_c('span',[_vm._v("미확인")])])]}}],null,true)}),_c('div',{staticClass:"text-center pt-4 d-flex justify-center align-center"},[(Math.ceil(_vm.totalItems / _vm.options.itemsPerPage) > 1)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page === 1},on:{"click":function($event){_vm.options.page = 1}}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")])],1):_vm._e(),(_vm.options.itemsPerPage > 0)?_c('v-pagination',{attrs:{"small":"","color":"primary","length":Math.ceil(_vm.totalItems / _vm.options.itemsPerPage),"totalVisible":"15","depressed":""},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e(),(Math.ceil(_vm.totalItems / _vm.options.itemsPerPage) > 1)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.options.page === Math.ceil(_vm.totalItems / _vm.options.itemsPerPage)},on:{"click":function($event){_vm.options.page = Math.ceil(_vm.totalItems / _vm.options.itemsPerPage)}}},[_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1):_vm._e()],1)],1),_c('dlg-post-regist',{attrs:{"show":_vm.registDialog.show,"title":_vm.registDialog.title,"content":_vm.registDialog.content,"type":1,"boardNo":_vm.boardNo,"func":_vm.getSummary},on:{"update:show":function($event){return _vm.$set(_vm.registDialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }