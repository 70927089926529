<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        {{ boardName }}
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              :loading="loading"
              color="primary"
              @click="getSummary()"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        v-if="
          (boardNo === 0 && this.userInfo.permission.admin.menu.notice.bool === true)
          || boardNo !== 0
        "
        @click="registDialog = { show: true, title: null, content: null, type: 0, files: [] }"
      >
        게시글작성
      </v-btn>
    </div>
    <div class="main-section">
      <search-filter
        :search="search"
        type="server"
        searchPlaceHolder="등록인/제목으로 검색"
        @updateSearch="updateSearch"
      />
      <v-data-table
        :headers="headers"
        :items="lists"
        item-key="no"
        :options="options"
        :loading="loading"
        class="elevation-0"
        dense
        checkbox-color="primary"
        :server-items-length="totalItems"
        hide-default-footer
        @update:sort-by="updateOptions('sortBy', $event)"
        @update:sort-desc="updateOptions('sortDesc', $event)"
      >
      <template #[`item.title`]="{ item }">
        <span class="cs-title" @click="contentsView(item.no)">{{ item.title }}</span>
      </template>
      <template #[`item.created`]="{ item }">
        {{ time.makeLocalTime(item.created, 'min') }}
      </template>
      <template #[`item.customer`]="{ item }">
        <v-tooltip top color="black">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{`
                ${item.customerName}
                (${item.customerPhone.slice(-4)})
              `}}
            </span>
          </template>
          <span>{{ item.customerPhone }}</span>
        </v-tooltip>
      </template>
      <template #[`item.manager`]="{ item }">
        <v-chip
          class="pl-1 pr-1"
          small label outlined
        >{{ comm.getGroupFullPath(groups, item.managerGroupNo, '그룹없음') }}</v-chip>
        {{ item.managerName }}
      </template>
      <template #[`item.creator`]="{ item }">
        <v-chip
          class="pl-1 pr-1"
          small label outlined
        >{{ comm.getGroupFullPath(groups, item.creatorGroupNo, '그룹없음') }}</v-chip>
        {{ item.creatorName }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-menu offset-y v-if="
          userInfo !== null
          && userInfo.permission.cs.menu.cs.func.status
          && userInfo.permission.cs.menu.cs.func.status.bool === true"
        >
          <template #activator="{ on, attrs }">
            <v-chip small link outlined
              class="pr-2"
              v-bind="attrs"
              v-on="on"
              :color="item.status === 1
                ? 'info'
                : (item.status === 2 ? 'success' : '')"
            >
              <span v-if="item.status === 1">진행중</span>
              <span v-else-if="item.status === 2">처리완료</span>
              <span v-else>미확인</span>
              <v-icon small class="ml-1">mdi-pencil</v-icon>
            </v-chip>
          </template>
          <v-list>
            <v-list-item dense nav link v-if="item.status !== 1"
              @click="updateStatus(item.no, 1)"
            >
              <v-list-item-title>진행중으로 변경</v-list-item-title>
            </v-list-item>
            <v-list-item dense nav link v-if="item.status !== 2"
              @click="updateStatus(item.no, 2)"
            >
              <v-list-item-title>처리완료로 변경</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-chip v-else small
          :color="item.status === 1
            ? 'info'
            : (item.status === 2 ? 'success' : '')"
        >
          <span v-if="item.status === 1">진행중</span>
          <span v-else-if="item.status === 2">처리완료</span>
          <span v-else>미확인</span>
        </v-chip>
      </template>
      </v-data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <dlg-post-regist
      :show.sync="registDialog.show"
      :title="registDialog.title"
      :content="registDialog.content"
      :type="1"
      :boardNo="boardNo"
      :func="getSummary"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DlgPostRegist from '@/components/dialog/DlgPostRegist.vue';
import time from '@/util/time';
import comm from '@/util/comm';

export default {
  components: {
    SearchFilter,
    DlgPostRegist,
  },
  props: {
    boardNo: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    comm,
    time,
    boardName: '',
    filePath: null,
    registDialog: {
      show: false,
      content: null,
      title: null,
      files: [],
    },
    viewDlg: {
      show: false,
      item: {
        title: null,
        content: null,
        memos: [],
        files: [],
        visitor: [],
      },
    },
    groups: [],
    loading: true,
    lists: [],
    search: '',
    totalItems: 0,
    options: {
      page: 1,
      itemsPerPage: 100,
      sortBy: ['created'],
      sortDesc: [true],
      filters: [
        {
          condition: 'inc',
          column: 'title',
          value: '',
        },
        {
          where: 'or',
          condition: 'inc',
          column: 'creatorName',
          value: '',
        },
      ],
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
    }),
    headers() {
      return [
        {
          text: '제목',
          value: 'title',
          align: 'left',
        },
        {
          text: '등록인',
          value: 'creator',
          align: 'left',
          width: 300,
        },
        {
          text: '등록일시',
          value: 'created',
          align: 'center',
          width: 150,
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      post: 'dialog/post',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    getList() {
      this.$socket.emit('board.post.list.get', { boardNo: this.boardNo, ...this.options }, (resp) => {
        if (resp.result === 'success') {
          this.lists = resp.items;
          this.loading = false;
          if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
            this.options.page = 1;
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getBoardInfo() {
      this.$socket.emit('board.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [{
          condition: 'eq',
          column: 'no',
          value: this.boardNo,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.boardName = resp.items[0].name;
          this.getSummary();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getSummary() {
      this.loading = true;
      this.$socket.emit('board.post.summary.get', {
        boardNo: this.boardNo,
        filters: this.options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.totalItems = resp.item.count;
          this.getList();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateOptions(type, value) {
      let valArr = [];
      valArr = [value];
      this.options[type] = valArr;
    },
    updateStatus(no, status) {
      this.$socket.emit('cs.update', {
        no,
        status,
      }, (resp) => {
        if (resp.result === 'success') {
          this.getSummary();
          this.alert(['success', '처리상태가 변경되었습니다.']);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    contentsView(no) {
      this.progress(true);
      this.$socket.emit('board.post.get', { no }, (resp) => {
        if (resp.result === 'success') {
          this.post({
            show: true,
            type: 1,
            func: () => {
              this.getSummary();
            },
            item: { ...resp.item },
          });
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    checkPermission(boardNo) {
      try {
        const userPermission = this.userInfo.permission.boards.menu[boardNo];
        let result = false;
        if (
          userPermission !== undefined
          && userPermission.bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          if (
            this.boardNo != null
            && (
              this.checkPermission(this.boardNo) === true
              || this.boardNo === 0
            )
          ) {
            this.getGroupList();
            this.getBoardInfo();
          } else {
            this.alert(['error', '잘못된 접근입니다.']);
            this.$router.push('/');
          }
        }
      },
    },
    boardNo: {
      immediate: true,
      handler(val) {
        if (
          val != null
          && (
            this.checkPermission(val) === true
            || val === 0
          )
        ) {
          this.getGroupList();
          this.getBoardInfo();
          if (this.$route.query.postNo !== undefined) {
            this.contentsView(Number(this.$route.query.postNo));
          }
        } else {
          this.alert(['error', '잘못된 접근입니다.']);
          this.$router.push('/');
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const value = val.status.value === '전체' ? '' : val.status.value;
          this.options.filters[2].value = value;
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[0].value = val;
          this.options.filters[1].value = val;
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.cs-title {
  cursor: pointer;
  display: block;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    // text-decoration: underline;
    color: darkblue;
  }
}
</style>
